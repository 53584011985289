import './App.css';
import { useState, useEffect } from 'react';
import { cardImages } from './cards';
import SingleCard from './components/SingleCard';

function App() {
	const [cards, setCards] = useState([]);
	const [turns, setTurns] = useState(0);
	const [choiceOne, setChoiceOne] = useState(null); // Store user choice 1
	const [choiceTwo, setChoiceTwo] = useState(null); // Store user choice 2
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		if (choiceOne && choiceTwo) {
			setDisabled(true) // Disable clicking other cards
			if (choiceOne.src === choiceTwo.src) {
				setCards(prevCards => { // Match cards
					return prevCards.map(card => { // Trigger this function for each card
						if (card.src === choiceOne.src) {
							return { ...card, matched: true }
						} else {
							return card
						}
					})
				})
				resetTurn();
			} else {
				setTimeout(() => resetTurn(), // Reset turn
					1000);
			}
		}
	}, [choiceOne, choiceTwo])

	useEffect(() => {
		shuffleCards()
	}, [])

	const shuffleCards = () =>{
		const shuffledCards = [...cardImages, ...cardImages] // Duplicate cards
			.sort(() => Math.random() -0.5)
			.map((card) => ({ ...card, id: Math.random() })) // Return random id for each item
																											
		setChoiceOne(null);
		setChoiceTwo(null);
		setCards(shuffledCards);
		setTurns(0);
	};

	const handleChoice = (card) =>{                                                
		choiceOne ? setChoiceTwo(card) : setChoiceOne(card)  
	};

	const resetTurn = () =>{
		setChoiceOne(null);
		setChoiceTwo(null);
		setTurns(prevTurns => prevTurns + 1 );
		setDisabled(false);
	};
	 
	

	return (
		<div className='app-wrapper container p-4'>
			<div className='intro'>
				<h1 className='my-5'>Something good is coming 😊 ...</h1>
				<div className='d-flex align-items-center my-3 w-75'>
					<button className='btn btn-warning' onClick={shuffleCards}>
						New Game
					</button>
					<p className='fs-6 mb-0 ms-3'>Turns: {turns}</p>
				</div>
				
			</div>

			<div className="card-grid">
				{cards.map(card => (
					<SingleCard 
						key={card.id} 
						card={card}
						handleChoice={handleChoice}
						flipped={(card === choiceOne) || (card === choiceTwo) || card.matched}
						disabled={disabled}
					/>
			))}</div>
		 </div>   

	);
}

export default App;


