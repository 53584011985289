export const cardImages = [
	{ 'src': '/img/1.png', 'matched': false },
	{ 'src': '/img/2.png', 'matched': false },
	{ 'src': '/img/3.png', 'matched': false },
	{ 'src': '/img/4.png', 'matched': false },
	{ 'src': '/img/5.png', 'matched': false },
	{ 'src': '/img/6.png', 'matched': false },
	{ 'src': '/img/7.png', 'matched': false },
	{ 'src': '/img/8.png', 'matched': false },
	{ 'src': '/img/9.png', 'matched': false },
	{ 'src': '/img/10.png', 'matched': false },
];
