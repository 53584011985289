import './SingleCard.css'

export default function SingleCard({ card, handleChoice, flipped, disabled }){
	const handleClick = () => {
		if(!disabled){
				handleChoice(card)                 
		}
	}
	return ( 
		<div className='card' > 
			<div className={flipped ? 'flipped' : ''}> 
				<img
					className='front w-100 d-block' 
					src={card.src} 
					alt='card front'
				/>
				<img 
					className='back w-100 d-block' 
					src='/img/cover.jpg' 
					alt='card back'
					onClick={handleClick}    
				/>
			</div>
		</div>
		);
}
 
